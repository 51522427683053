import React from "react"
import { randint, flash } from "./common"

function throwDice(num, max) {
  if (num > 1) {
    let res = []
    for (let i=0; i<num; i++) {
      res.push(randint(max))
    }
    return `${res} = ${res.reduce((a,b) => a+b)}`
  } else {
    return randint(max)
  }
}

function rollDice(props, num, setRes, textInput) {
  textInput.current.style.color = "red"
  setTimeout(() => textInput.current.style.color = null, 1000)
  for (let i=0; i<10; i++) {
    setTimeout(() => setRes(throwDice(num, props.type)), i*100)
  }
  flash(textInput)
}

export default function Dice(props) {
  const [res, setRes] = React.useState(0)
  const [num, setNum] = React.useState(1)
  const addDice = () => setNum(num+1)
  const removeDice = () => (num > 1) ? setNum(num-1) : null
  const textInput = React.useRef(null)
  const handleClick = () => {
    rollDice(props, num, setRes, textInput)
  }
  return <div className="field has-addons">
    <div className="control">
      <button className="button is-link" style={{width:'100px'}}
              onClick={handleClick}>
      {num}d{props.type}
      </button>
    </div>
    <div className="control is-expanded">
      <input ref={textInput} className="input" type="text" value={res} readOnly />
    </div>
    <div className="control">
      <button className="button is-link" onClick={addDice}>+</button>
    </div>
    <div className="control">
      <button className="button is-link" onClick={removeDice}>-</button>
    </div>
  </div>
}
